import React from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"

const IndexPage = ({ pageContext, location }) => {
  // if (location?.href?.toLowerCase()?.includes("covid")) navigate("/sign-in")

  return (
    <Layout seoTitle="Home">
      <Container isCentered>
        <div className="my-6">
          <h1 className="is-size-1 has-text-primary">
            MedGrocer’s COVID-19 vaccination program has ended.
          </h1>
          <p className="is-size-5">
            For inquiries, please reach out to{" "}
            <a href="mailto:vax@medgrocer.com">vax@medgrocer.com</a>. Thank you
            for letting us serve you.
          </p>
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage
